const searchResults = [
    {
        name: 'Roy Walter',
        firstName: 'Roy',
        lastName: 'Walter',
        recipientId: '100006',
        bloodGroup: 'A+ (optional)',
        units: 6,
        productCode: 'code006',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        unitId: '12345670'
    },
    {
        name: 'hari krishna',
        firstName: 'hari',
        lastName: 'krishna',
        recipientId: '100006',
        bloodGroup: 'b+ (optional)',
        units: 6,
        productCode: 'code21',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        unitId: '12345670'
    },
    {
        name: 'hari krishna',
        firstName: 'hari',
        lastName: 'krishna',
        recipientId: '100006',
        bloodGroup: 'b+ (optional)',
        units: 6,
        productCode: 'code24',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        unitId: '12345670'
    },
    {
        firstName: 'Walter',
        lastName: 'White',
        name: 'Walter White',
        recipientId: '100005',
        bloodGroup: 'B- (optional)',
        units: 6,
        productCode: 'code007',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        unitId: '12345671'
    },
    {
        firstName: 'Jessy',
        lastName: 'White',
        name: 'Jessy White',
        recipientId: '100004',
        bloodGroup: 'O+ (optional)',
        units: 6,
        productCode: 'code008',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        unitId: '12345672'
    },
    {
        firstName: 'Walter',
        lastName: 'Jr.',
        name: 'Walter Jr.',
        recipientId: '100001',
        bloodGroup: 'A+ (optional)',
        units: 6,
        productCode: 'code006',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        unitId: '12345673'
    },
    {
        firstName: 'Mr.',
        lastName: 'White',
        name: 'Mr. White',
        recipientId: '100002',
        bloodGroup: 'B- (optional)',
        units: 6,
        productCode: 'code007',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        unitId: '12345674'
    },
    {
        firstName: 'Jessy',
        lastName: 'Pinkmen',
        name: 'Jessy Pinkmen',
        recipientId: 'Wa0003',
        bloodGroup: 'O+ (optional)',
        units: 6,
        productCode: 'code007',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        unitId: '12345675'
    }
];

let preEncodeData = [
    {
        Name: 'Roy Walter',
        firstName: 'Roy',
        lastName: 'Walter',
        recipientId: '100006',
        bloodGroup: 'A+',
        units: 6,
        productCode: 'code006',
        dob: '22/07/1993',
        gender: 'Male',
        check:false,
        dereservationDate: '2017-05-24 10:30',
        UnitID: '12345670',
        rfidNumber: 'e34387u47tu74r8',
        ExpiryDateandTime: '02-FEB-2023 3.30PM',
        SpecialTesting: 'ugvbuby',
        CollectionDateandTime: '02-FEB-2022 3.30PM',
        isAssociate:true,
        isAssigned: false
    },
    {
        Name: 'hari krishna',
        firstName: 'hari',
        lastName: 'krishna',
        recipientId: '100006',
        bloodGroup: 'b+',
        units: 6,
        productCode: 'code21',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        UnitID: '12345670',
        TagID: 'e34387u47tu74r8',
        ExpiryDateandTime: '22-AUG-2023 12.00 AM',
        SpecialTesting: 'gyugyu',
        CollectionDateandTime: '22-AUG-2022 12.00 AM',
        isAssigned: 'false'
    },
    {
        Name: 'hari krishna',
        firstName: 'hari',
        lastName: 'krishna',
        recipientId: '100006',
        bloodGroup: 'b+',
        units: 6,
        productCode: 'code24',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        UnitID: '12345670',
        TagID: 'e34387u47tu74r8',
        ExpiryDateandTime: '30-APR-2023 4.50 PM',
        SpecialTesting: 'fdt9t6g',
        CollectionDateandTime: '30-APR-2022 4.50 PM',
        isAssigned: 'false'
    },
    {
        firstName: 'Walter',
        lastName: 'White',
        Name: 'Walter White',
        recipientId: '100005',
        bloodGroup: 'B-',
        units: 6,
        productCode: 'code007',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        UnitID: '12345671',
        TagID: 'e34387u47tu74r8',
        ExpiryDateandTime: '10-JAN-2023 7.00 AM',
        SpecialTesting: 'dqd1229',
        CollectionDateandTime: '10-JAN-2022 7.00 AM',
        isAssigned: 'false'
    },
    {
        firstName: 'Jessy',
        lastName: 'White',
        Name: 'Jessy White',
        recipientId: '100004',
        bloodGroup: 'O+',
        units: 6,
        productCode: 'code008',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        UnitID: '12345672',
        TagID: 'e34387u47tu74r8',
        ExpiryDateandTime: '15-JUL-2023 9.00 PM',
        SpecialTesting: 'fnu38r8',
        CollectionDateandTime: '15-JUL-2022 9.00 PM',
        isAssigned: 'false'
    },
    {
        firstName: 'Walter',
        lastName: 'Jr.',
        Name: 'Walter Jr.',
        recipientId: '100001',
        bloodGroup: 'A+',
        units: 6,
        productCode: 'code006',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        UnitID: '12345673',
        TagID: 'e34387u47tu74r8',
        ExpiryDateandTime: '23-AUG-2023 1.10 PM',
        SpecialTesting: 'dhy8d12812',
        CollectionDateandTime: '23-AUG-2022 1.10 PM',
        isAssigned: 'false'
    },
    {
        firstName: 'Mr.',
        lastName: 'White',
        Name: 'Mr. White',
        recipientId: '100002',
        bloodGroup: 'B-',
        units: 6,
        productCode: 'code007',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        UnitID: '12345674',
        TagID: 'e34387u47tu74r8',
        ExpiryDateandTime: '27-SEPT-2023 2.45 AM',
        SpecialTesting: 'wehifh2783y',
        CollectionDateandTime: '27-SEPT-2022 2.45 AM',
        isAssigned: 'false'
    },
    {
        firstName: 'Jessy',
        lastName: 'Pinkmen',
        Name: 'Jessy Pinkmen',
        recipientId: 'Wa0003',
        bloodGroup: 'O+',
        units: 6,
        productCode: 'code007',
        dob: '22/07/1993',
        gender: 'Male',
        dereservationDate: '2017-05-24 10:30',
        UnitID: '12345675',
        TagID: 'e34387u47tu74r8',
        ExpiryDateandTime: '18-JAN-2023 12.30 PM',
        SpecialTesting: 'ef4r747r43fh',
        CollectionDateandTime: '18-JAN-2022 12.30 PM',
        isAssigned: 'false'
    }
];

const unitTable = [];

export { searchResults, unitTable, preEncodeData };
