import packageJson from '../../package.json';

const CONSTANTS = {};

CONSTANTS.LINK_DASHBOARD = '/dashboard';
CONSTANTS.LINK_ASSOCIATE_UNIT = '/dashboard/associate-unit';
CONSTANTS.LINK_ASSIGN_UNIT = '/dashboard/assign-unit';
CONSTANTS.LINK_MOVEMENTS = '/dashboard/reports/movements';
CONSTANTS.LINK_DEVICE_PROCESS = '/dashboard/device-process';
CONSTANTS.LINK_FATED_WASTED = '/dashboard/reports/fated';
CONSTANTS.LINK_NOTIFICATIONS = '/dashboard/reports/notifications';
CONSTANTS.LINK_HEALTH_AUTHORITY = '/dashboard/manage/health-authority';
CONSTANTS.LINK_LOCATION = '/dashboard/manage/location';
CONSTANTS.LINK_PRODUCT = '/dashboard/manage/product';
CONSTANTS.LINK_MANAGE_STOCK = '/dashboard/manage/manage-stock';
CONSTANTS.LINK_ALERTS_WARNINGS = '/dashboard/manage/alerts-warnings';
CONSTANTS.LINK_DERESERVATIONED = '/dashboard/reports/dereservationed';
CONSTANTS.LINK_ASSIGNED = '/dashboard/reports/assigned';
CONSTANTS.LINK_STOCK = '/dashboard/reports/stock';
CONSTANTS.LINK_HOSPITAL = '/dashboard/manage/hospital';
CONSTANTS.LINK_DEVICE = '/dashboard/manage/device';
CONSTANTS.LINK_USER = '/dashboard/manage/user';
CONSTANTS.LINK_MANAGE_MOVEMENT = '/dashboard/manage/movement';
CONSTANTS.LINK_SETTINGS = '/dashboard/settings';
CONSTANTS.LINK_MANAGE_RULE = '/dashboard/manage/rule';

CONSTANTS.NAME = 'Name';
CONSTANTS.CODE = 'Code';
CONSTANTS.NAME_DASHBOARD = 'Dashboard';
CONSTANTS.NAME_ASSOCIATE_UNIT = 'Associate Units';
CONSTANTS.NAME_ASSIGN_UNIT = 'Assign Unit';
CONSTANTS.NAME_REPORTS = 'Reports';
CONSTANTS.NAME_MOVEMENTS = 'Movement';
CONSTANTS.NAME_MOVEMENTSS = 'Movements';
CONSTANTS.NAME_REMOVED = 'Removed';
CONSTANTS.NAME_DERESERVATIONED = 'Dereservation';
CONSTANTS.NAME_DERESERVATIONS = 'Dereservations';
CONSTANTS.NAME_STOCK = 'Stock';
CONSTANTS.NAME_STOCKS = 'Stocks';
CONSTANTS.NAME_ASSIGNED = 'Assigned';
CONSTANTS.NAME_MANAGE = 'Manage';
CONSTANTS.NAME_HOSPITAL = 'Branch';
CONSTANTS.NAME_HOSPITALS = 'Branches';
CONSTANTS.NAME_DEVICE = 'Device';
CONSTANTS.NAME_DEVICES = 'Devices';
CONSTANTS.NAME_LOCATION = 'Location';
CONSTANTS.LABELS_LOCATION = 'Location';
CONSTANTS.NAME_LOCATIONS = 'Locations';
CONSTANTS.NAME_USER = 'User';
CONSTANTS.NAME_USERS = 'Users';
CONSTANTS.NAME_MANAGE_MOVEMENT = 'Movement';
CONSTANTS.NAME_MANAGE_MOVEMENTS = 'Movements';
CONSTANTS.NAME_SETTINGS = 'Settings';
CONSTANTS.NAME_RESET = 'Reset';
CONSTANTS.NAME_APPLY = 'Apply';
CONSTANTS.NAME_ADD = 'Add';
CONSTANTS.NAME_ALERTS_WARNINGS = 'Alerts & Warnings';
CONSTANTS.NAME_PRODUCT = 'Product';
CONSTANTS.NAME_PRODUCTS = 'Products';
CONSTANTS.NAME_HEALTH_AUTHORITY = 'Health Authority';
CONSTANTS.NAME_HEALTH_AUTHORITIES = 'Health Authorities';
CONSTANTS.NAME_FILTER = 'Filter';
CONSTANTS.NAME_DEVICE_PROCESS = 'Device Process';
CONSTANTS.NAME_FATED_WASTED = 'Fated';
CONSTANTS.NAME_NOTIFICATIONS = 'Notifications';
CONSTANTS.NAME_DESCRIPTION = 'Description';
CONSTANTS.NAME_TYPE = 'Type';
CONSTANTS.NAME_MANAGE_RULE = 'Rule';
CONSTANTS.NAME_RULES = 'Rules';

// reducers
CONSTANTS.USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
CONSTANTS.USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
CONSTANTS.USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
CONSTANTS.USER_IS_LOGGEDIN = 'USER_IS_LOGGEDIN';
CONSTANTS.USER_LOGOUT = 'USER_LOGOUT';

CONSTANTS.GET_OTP_REQUEST = 'GET_OTP_REQUEST';
CONSTANTS.GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
CONSTANTS.GET_OTP_ERROR = 'GET_OTP_ERROR';

CONSTANTS.VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
CONSTANTS.VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
CONSTANTS.VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';
CONSTANTS.OTP_LOGGIN_SUCCESS = 'OTP_LOGGIN_SUCCESS';

CONSTANTS.CLEAR_OTP_LOGIN_RESPONSES = 'CLEAR_OTP_LOGIN_RESPONSES';

CONSTANTS.GET_FORM_TABLE_DATA_REQUEST = 'GET_FORM_TABLE_DATA_REQUEST';
CONSTANTS.GET_FORM_TABLE_DATA_SUCCESS = 'GET_FORM_TABLE_DATA_SUCCESS';
CONSTANTS.GET_FORM_TABLE_DATA_FAIL = 'GET_FORM_TABLE_DATA_FAIL';

CONSTANTS.GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST';
CONSTANTS.GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
CONSTANTS.GET_DEVICE_FAIL = 'GET_DEVICE_FAIL';

CONSTANTS.ADD_DEVICE_REQUEST = 'ADD_DEVICE_REQUEST';
CONSTANTS.ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
CONSTANTS.ADD_DEVICE_FAIL = 'ADD_DEVICE_FAIL';

CONSTANTS.UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST';
CONSTANTS.UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
CONSTANTS.UPDATE_DEVICE_FAIL = 'UPDATE_DEVICE_FAIL';

CONSTANTS.DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST';
CONSTANTS.DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
CONSTANTS.DELETE_DEVICE_FAIL = 'DELETE_DEVICE_FAIL';

CONSTANTS.GET_DEVICETYPE_REQUEST = 'GET_DEVICETYPE_REQUEST';
CONSTANTS.GET_DEVICETYPE_SUCCESS = 'GET_DEVICETYPE_SUCCESS';
CONSTANTS.GET_DEVICETYPE_FAIL = 'GET_DEVICETYPE_FAIL';

CONSTANTS.GET_LOCATIONTYPE_REQUEST = 'GET_LOCATIONTYPE_REQUEST';
CONSTANTS.GET_LOCATIONTYPE_SUCCESS = 'GET_LOCATIONTYPE_SUCCESS';
CONSTANTS.GET_LOCATIONTYPE_FAIL = 'GET_LOCATIONTYPE_FAIL';

CONSTANTS.GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
CONSTANTS.GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
CONSTANTS.GET_CLIENT_FAIL = 'GET_CLIENT_FAIL';

CONSTANTS.GET_LOCATION_TYPE_REQUEST = 'GET_LOCATION_TYPE_REQUEST';
CONSTANTS.GET_LOCATION_TYPE_SUCCESS = 'GET_LOCATION_TYPE_SUCCESS';
CONSTANTS.GET_LOCATION_TYPE_FAIL = 'GET_LOCATION_TYPE_FAIL';

CONSTANTS.GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
CONSTANTS.GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
CONSTANTS.GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';

CONSTANTS.ADD_LOCATION_REQUEST = 'ADD_LOCATION_REQUEST';
CONSTANTS.ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
CONSTANTS.ADD_LOCATION_FAIL = 'ADD_LOCATION_FAIL';

CONSTANTS.UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
CONSTANTS.UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
CONSTANTS.UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';

CONSTANTS.DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST';
CONSTANTS.DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
CONSTANTS.DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';

CONSTANTS.SET_API_RESPONSE_TYPE = 'SET_API_RESPONSE_TYPE';
CONSTANTS.SET_ROUTE_TYPE = 'SET_ROUTE_TYPE';
CONSTANTS.SET_THEME_COLOR = 'SET_THEME_COLOR';

CONSTANTS.GET_SCREEN_REQUEST = 'GET_SCREEN_REQUEST';
CONSTANTS.GET_SCREEN_SUCCESS = 'GET_SCREEN_SUCCESS';
CONSTANTS.GET_SCREEN_FAIL = 'GET_SCREEN_FAIL';

CONSTANTS.GET_DASHBOARDEUO_REQUEST = 'GET_DASHBOARDEUO_REQUEST';
CONSTANTS.GET_DASHBOARDEUO_SUCCESS = 'GET_DASHBOARDEUO_SUCCESS';
CONSTANTS.GET_DASHBOARDEUO_FAIL = 'GET_DASHBOARDEUO_FAIL';

CONSTANTS.GET_DASHBOARDEUO_UNITS_REQUEST = 'GET_DASHBOARDEUO_UNITS_REQUEST';
CONSTANTS.GET_DASHBOARDEUO_UNITS_SUCCESS = 'GET_DASHBOARDEUO_UNITS_SUCCESS';
CONSTANTS.GET_DASHBOARDEUO_UNITS_FAIL = 'GET_DASHBOARDEUO_UNITS_FAIL';

CONSTANTS.GET_DASHBOARDEUO_BATCH_REQUEST = 'GET_DASHBOARDEUO_BATCH_REQUEST';
CONSTANTS.GET_DASHBOARDEUO_BATCH_SUCCESS = 'GET_DASHBOARDEUO_BATCH_SUCCESS';
CONSTANTS.GET_DASHBOARDEUO_BATCH_FAIL = 'GET_DASHBOARDEUO_BATCH_FAIL';

CONSTANTS.PUT_ACKNOTIFY_REQUEST = 'PUT_ACKNOTIFY_REQUEST';
CONSTANTS.PUT_ACKNOTIFY_SUCCESS = 'PUT_ACKNOTIFY_SUCCESS';
CONSTANTS.PUT_ACKNOTIFY_FAIL = 'PUT_ACKNOTIFY_FAIL';

CONSTANTS.GET_ACKNOTIFY_REQUEST = 'GET_ALERTSEUO_REQUEST';
CONSTANTS.GET_ACKNOTIFY_SUCCESS = 'GET_ALERTSEUO_SUCCESS';
CONSTANTS.GET_ACKNOTIFY_FAIL = 'GET_ALERTSEUO_FAIL';

CONSTANTS.GET_ALERTSAVEUO_REQUEST = 'GET_ALERTSAVEUO_REQUEST';
CONSTANTS.GET_ALERTSAVEUO_SUCCESS = 'GET_ALERTSAVEUO_SUCCESS';
CONSTANTS.GET_ALERTSAVEUO_FAIL = 'GET_ALERTSAVEUO_FAIL';

CONSTANTS.GET_TEMP_SCREEN_REQUEST = 'GET_TEMP_SCREEN_REQUEST';
CONSTANTS.GET_TEMP_SCREEN_SUCCESS = 'GET_TEMP_SCREEN_SUCCESS';
CONSTANTS.GET_TEMP_SCREEN_FAIL = 'GET_TEMP_SCREEN_FAIL';


CONSTANTS.GET_TEMP_GRAPH_REQUEST = 'GET_TEMP_GRAPH_REQUEST';
CONSTANTS.GET_TEMP_GRAPH_SUCCESS = 'GET_TEMP_GRAPH_SUCCESS';
CONSTANTS.GET_TEMP_GRAPH_FAIL = 'GET_TEMP_GRAPH_FAIL';

CONSTANTS.GET_STATUS_REPORT_REQUEST = 'GET_STATUS_REPORT_REQUEST';
CONSTANTS.GET_STATUS_REPORT_SUCCESS = 'GET_STATUS_REPORT_SUCCESS';
CONSTANTS.GET_STATUS_REPORT_FAIL = 'GET_STATUS_REPORT_FAIL';

CONSTANTS.SET_DATE_FORMAT = 'SET_DATE_FORMAT';

CONSTANTS.GET_FILTER_CRITERIA_REQUEST = 'GET_FILTER_CRITERIA_REQUEST';
CONSTANTS.FILTER_CRITERIA_REQUEST_SUCCESS = 'FILTER_CRITERIA_REQUEST_SUCCESS';
CONSTANTS.FILTER_CRITERIA_REQUEST_ERROR = 'FILTER_CRITERIA_REQUEST_ERROR';

CONSTANTS.GET_FILTER_REQUEST = 'GET_FILTER_REQUEST';
CONSTANTS.GET_FILTER_SUCCESS = 'GET_FILTER_SUCCESS';
CONSTANTS.GET_FILTER_ERROR = 'GET_FILTER_ERROR';

CONSTANTS.GET_REQUESTBATCH_FILTER_REQUEST = 'GET_REQUESTBATCH_FILTER_REQUEST';
CONSTANTS.GET_REQUESTBATCH_FILTER_SUCCESS = 'GET_REQUESTBATCH_FILTER_SUCCESS';
CONSTANTS.GET_REQUESTBATCH_FILTER_ERROR = 'GET_REQUESTBATCH_FILTER_ERROR';

CONSTANTS.GET_ACTIVITYUNIT_FILTER_REQUEST = 'GET_ACTIVITYUNIT_FILTER_REQUEST';
CONSTANTS.GET_ACTIVITYUNIT_FILTER_SUCCESS = 'GET_ACTIVITYUNIT_FILTER_SUCCESS';
CONSTANTS.GET_ACTIVITYUNIT_FILTER_ERROR = 'GET_ACTIVITYUNIT_FILTER_ERROR';

CONSTANTS.GET_ACTIVITYBATCH_FILTER_REQUEST = 'GET_ACTIVITYBATCH_FILTER_REQUEST';
CONSTANTS.GET_ACTIVITYBATCH_FILTER_SUCCESS = 'GET_ACTIVITYBATCH_FILTER_SUCCESS';
CONSTANTS.GET_ACTIVITYBATCH_FILTER_ERROR = 'GET_ACTIVITYBATCH_FILTER_ERROR';

CONSTANTS.GET_NOTIFYUNIT_FILTER_REQUEST = 'GET_NOTIFYUNIT_FILTER_REQUEST';
CONSTANTS.GET_NOTIFYUNIT_FILTER_SUCCESS = 'GET_NOTIFYUNIT_FILTER_SUCCESS';
CONSTANTS.GET_NOTIFYUNIT_FILTER_ERROR = 'GET_NOTIFYUNIT_FILTER_ERROR';

CONSTANTS.GET_NOTIFYBATCH_FILTER_REQUEST = 'GET_NOTIFYBATCH_FILTER_REQUEST';
CONSTANTS.GET_NOTIFYBATCH_FILTER_SUCCESS = 'GET_NOTIFYBATCH_FILTER_SUCCESS';
CONSTANTS.GET_NOTIFYBATCH_FILTER_ERROR = 'GET_NOTIFYBATCH_FILTER_ERROR';

CONSTANTS.GET_WASTEDUNIT_FILTER_REQUEST = 'GET_WASTEDUNIT_FILTER_REQUEST';
CONSTANTS.GET_WASTEDUNIT_FILTER_SUCCESS = 'GET_WASTEDUNIT_FILTER_SUCCESS';
CONSTANTS.GET_WASTEDUNIT_FILTER_ERROR = 'GET_WASTEDUNIT_FILTER_ERROR';

CONSTANTS.GET_WASTEDBATCH_FILTER_REQUEST = 'GET_WASTEDBATCH_FILTER_REQUEST';
CONSTANTS.GET_WASTEDBATCH_FILTER_SUCCESS = 'GET_WASTEDBATCH_FILTER_SUCCESS';
CONSTANTS.GET_WASTEDBATCH_FILTER_ERROR = 'GET_WASTEDBATCH_FILTER_ERROR';

CONSTANTS.GET_TRANSFUSEDUNIT_FILTER_REQUEST = 'GET_TRANSFUSEDUNIT_FILTER_REQUEST';
CONSTANTS.GET_TRANSFUSEDUNIT_FILTER_SUCCESS = 'GET_TRANSFUSEDUNIT_FILTER_SUCCESS';
CONSTANTS.GET_TRANSFUSEDUNIT_FILTER_ERROR = 'GET_TRANSFUSEDUNIT_FILTER_ERROR';


CONSTANTS.GET_TRANSFUSEDBATCH_FILTER_REQUEST = 'GET_TRANSFUSEDBATCH_FILTER_REQUEST';
CONSTANTS.GET_TRANSFUSEDBATCH_FILTER_SUCCESS = 'GET_TRANSFUSEDBATCH_FILTER_SUCCESS';
CONSTANTS.GET_TRANSFUSEDBATCH_FILTER_ERROR = 'GET_TRANSFUSEDBATCH_FILTER_ERROR';

CONSTANTS.GET_RECIPIENT_FILTER_REQUEST = 'GET_RECIPIENT_FILTER_REQUEST';
CONSTANTS.GET_RECIPIENT_FILTER_SUCCESS = 'GET_RECIPIENT_FILTER_SUCCESS';
CONSTANTS.GET_RECIPIENT_FILTER_ERROR = 'GET_RECIPIENT_FILTER_ERROR';

CONSTANTS.GET_USER_FILTER_REQUEST = 'GET_USER_FILTER_REQUEST';
CONSTANTS.GET_USER_FILTER_SUCCESS = 'GET_USER_FILTER_SUCCESS';
CONSTANTS.GET_USER_FILTER_ERROR = 'GET_USER_FILTER_ERROR';

CONSTANTS.GET_SWAPOUT_FILTER_REQUEST = 'GET_SWAPOUT_FILTER_REQUEST';
CONSTANTS.GET_SWAPOUT_FILTER_SUCCESS = 'GET_SWAPOUT_FILTER_SUCCESS';
CONSTANTS.GET_SWAPOUT_FILTER_ERROR = 'GET_SWAPOUT_FILTER_ERROR';

CONSTANTS.GET_DATA_REQUEST = 'GET_DATA_REQUEST';
CONSTANTS.GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
CONSTANTS.GET_DATA_ERROR = 'GET_DATA_ERROR';
CONSTANTS.CLEAR_DATA = 'CLEAR_DATA';
CONSTANTS.CLEAR_FILTER_CRITERIA = 'CLEAR_FILTER_CRITERIA';

CONSTANTS.GET_USER_ROLE_REQUEST = 'GET_USER_ROLE_REQUEST';
CONSTANTS.GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS';
CONSTANTS.GET_USER_ROLE_ERROR = 'GET_USER_ROLE_ERROR';

CONSTANTS.GET_DEVICE_STATUS = 'GET_DEVICE_STATUS';
CONSTANTS.GET_DEVICE_STATUS_SUCCESS = 'GET_DEVICE_STATUS_SUCCESS';
CONSTANTS.GET_DEVICE_STATUS_ERROR = 'GET_DEVICE_STATUS_ERROR';

CONSTANTS.UPDATE_DATA_REQUEST = 'UPDATE_DATA_REQUEST';
CONSTANTS.UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
CONSTANTS.UPDATE_DATA_FAIL = 'UPDATE_DATA_FAIL';

CONSTANTS.DELETE_ROW_REQUEST = 'DELETE_ROW_REQUEST';
CONSTANTS.DELETE_ROW_SUCCESS = 'DELETE_ROW_SUCCESS';
CONSTANTS.DELETE_ROW_FAIL = 'DELETE_ROW_FAIL';

CONSTANTS.GET_USER_GROUPS_REQUEST = 'GET_USER_GROUPS_REQUEST';
CONSTANTS.GET_USER_GROUPS_SUCCESS = 'GET_USER_GROUPS_SUCCESS';
CONSTANTS.GET_USER_GROUPS_FAIL = 'GET_USER_GROUPS_FAIL';

CONSTANTS.GET_DEVICE_TYPE_REQUEST = 'GET_DEVICE_TYPE_REQUEST';
CONSTANTS.GET_DEVICE_TYPE_SUCCESS = 'GET_DEVICE_TYPE_SUCCESS';
CONSTANTS.GET_DEVICE_TYPE_FAIL = 'GET_DEVICE_TYPE_FAIL';

CONSTANTS.GET_CLIENT_ID_REQUST = 'GET_CLIENT_ID_REQUST';
CONSTANTS.GET_CLIENT_ID_SUCCESS = 'GET_CLIENT_ID_SUCCESS';
CONSTANTS.GET_CLIENT_ID_FAIL = 'GET_CLIENT_ID_FAIL';

CONSTANTS.GET_DOWNLOAD_DATA_REQUEST = 'GET_DOWNLOAD_DATA_REQUEST';
CONSTANTS.GET_DOWNLOAD_DATA_SUCCESS = 'GET_DOWNLOAD_DATA_SUCCESS';
CONSTANTS.GET_DOWNLOAD_DATA_FAIL = 'GET_DOWNLOAD_DATA_FAIL';

CONSTANTS.UNMOUNT_DISPLAY_CONFIG_DATA = 'UNMOUNT_DISPLAY_CONFIG_DATA';

CONSTANTS.UPLOAD_EXCEL_FILE_REQUEST = 'UPLOAD_EXCEL_FILE_REQUEST';
CONSTANTS.UPLOAD_EXCEL_FILE_SUCCESS = 'UPLOAD_EXCEL_FILE_SUCCESS';
CONSTANTS.UPLOAD_EXCEL_FILE_FAIL = 'UPLOAD_EXCEL_FILE_FAIL';

CONSTANTS.POST_DATA_REQUEST = 'POST_DATA_REQUEST';
CONSTANTS.POST_DATA_SUCCESS = 'POST_DATA_SUCCESS';
CONSTANTS.POST_DATA_ERROR = 'POST_DATA_ERROR';

CONSTANTS.GET_DRAWER_REQUEST = 'GET_DRAWER_REQUEST';
CONSTANTS.GET_DRAWER_SUCCESS = 'GET_DRAWER_SUCCESS';
CONSTANTS.GET_DRAWER_FAIL = 'GET_DRAWER_FAIL';

CONSTANTS.GET_DROPDOWN_REQUEST = 'GET_DROPDOWN_REQUEST';
CONSTANTS.GET_DROPDOWN_SUCCESS = 'GET_DROPDOWN_SUCCESS';
CONSTANTS.GET_DROPDOWN_ERROR = 'GET_DROPDOWN_ERROR';

CONSTANTS.GET_SETTINGS_TABLE_REQUEST = 'GET_SETTINGS_TABLE_REQUEST';
CONSTANTS.GET_SETTINGS_TABLE_SUCCESS = 'GET_SETTINGS_TABLE_SUCCESS';
CONSTANTS.GET_SETTINGS_TABLE_ERROR = 'GET_SETTINGS_TABLE_ERROR';
CONSTANTS.CLEAR_SETTINGS_TABLE = 'CLEAR_SETTINGS_TABLE';

CONSTANTS.GET_OTHER_DROPDOWN_REQUEST = 'GET_OTHER_DROPDOWN_REQUEST';
CONSTANTS.GET_OTHER_DROPDOWN_SUCCESS = 'GET_OTHER_DROPDOWN_SUCCESS';
CONSTANTS.GET_OTHER_DROPDOWN_ERROR = 'GET_OTHER_DROPDOWN_ERROR';

CONSTANTS.GET_3RD_DROPDOWN_REQUEST = 'GET_3RD_DROPDOWN_REQUEST';
CONSTANTS.GET_3RD_DROPDOWN_SUCCESS = 'GET_3RD_DROPDOWN_SUCCESS';
CONSTANTS.GET_3RD_DROPDOWN_ERROR = 'GET_3RD_DROPDOWN_ERROR';

CONSTANTS.GET_4TH_DROPDOWN_REQUEST = 'GET_4TH_DROPDOWN_REQUEST';
CONSTANTS.GET_4TH_DROPDOWN_SUCCESS = 'GET_4TH_DROPDOWN_SUCCESS';
CONSTANTS.GET_4TH_DROPDOWN_ERROR = 'GET_4TH_DROPDOWN_ERROR';

CONSTANTS.GET_5TH_DROPDOWN_REQUEST = 'GET_5TH_DROPDOWN_REQUEST';
CONSTANTS.GET_5TH_DROPDOWN_SUCCESS = 'GET_5TH_DROPDOWN_SUCCESS';
CONSTANTS.GET_5TH_DROPDOWN_ERROR = 'GET_5TH_DROPDOWN_ERROR';

CONSTANTS.GET_6TH_DROPDOWN_REQUEST = 'GET_6TH_DROPDOWN_REQUEST';
CONSTANTS.GET_6TH_DROPDOWN_SUCCESS = 'GET_6TH_DROPDOWN_SUCCESS';
CONSTANTS.GET_6TH_DROPDOWN_ERROR = 'GET_6TH_DROPDOWN_ERROR';

CONSTANTS.GET_7TH_DROPDOWN_REQUEST = 'GET_7TH_DROPDOWN_REQUEST';
CONSTANTS.GET_7TH_DROPDOWN_SUCCESS = 'GET_7TH_DROPDOWN_SUCCESS';
CONSTANTS.GET_7TH_DROPDOWN_ERROR = 'GET_7TH_DROPDOWN_ERROR';

CONSTANTS.PUT_HEADER_ACTIONS = 'PUT_HEADER_ACTIONS';
CONSTANTS.PUT_HEADER_ACTIONS_SUCCESS = 'PUT_HEADER_ACTIONS_SUCCESS';
CONSTANTS.PUT_HEADER_ACTIONS_ERROR = 'PUT_HEADER_ACTIONS_ERROR';
CONSTANTS.PUT_HEADER_ACTIONS_CLEAR = 'PUT_HEADER_ACTIONS_CLEAR';

CONSTANTS.POST_REQUESTBATCH_ACTIONS = 'CONSTANTS.POST_REQUESTBATCH_ACTIONS';
CONSTANTS.POST_REQUESTBATCH_ACTIONS_SUCCESS = 'CONSTANTS.POST_REQUESTBATCH_ACTIONS_SUCCESS';
CONSTANTS.POST_REQUESTBATCH_ACTIONS_ERROR = 'CONSTANTS.POST_REQUESTBATCH_ACTIONS_ERROR';
CONSTANTS.POST_REQUESTBATCH_ACTIONS_CLEAR = ' CONSTANTS.POST_REQUESTBATCH_ACTIONS_CLEAR';

CONSTANTS.PUT_ACTIONS = 'PUT_ACTIONS';
CONSTANTS.PUT_ACTIONS_SUCCESS = 'PUT_ACTIONS_SUCCESS';
CONSTANTS.PUT_ACTIONS_ERROR = 'PUT_ACTIONS_ERROR';
CONSTANTS.PUT_ACTIONS_CLEAR = 'PUT_ACTIONS_CLEAR';

CONSTANTS.HEADER_LED_ACTIONS = 'HEADER_LED_ACTIONS';
CONSTANTS.HEADER_LED_ACTIONS_SUCCESS = 'HEADER_LED_ACTIONS_SUCCESS';
CONSTANTS.HEADER_LED_ACTIONS_ERROR = 'HEADER_LED_ACTIONS_ERROR';
CONSTANTS.HEADER_LED_CLEAR = 'HEADER_LED_CLEAR';

//labels
CONSTANTS.USERNAME = 'Username';
CONSTANTS.PASSWORD = 'Password';
CONSTANTS.SECURE_LOGIN = 'Secure Login';
CONSTANTS.EMAIL = 'Email';
CONSTANTS.OTP = 'OTP';

//other_strings
CONSTANTS.STRINGS_ADMIN = 'ADMIN';
CONSTANTS.STRINGS_FORGOTPASSWORD = 'Forgot Password ?';
CONSTANTS.STRINGS_SIGNIN = 'Sign In';
CONSTANTS.STRINGS_BACK = 'BACK';
CONSTANTS.STRINGS_COPYRIGHT = 'Copyright © ';
CONSTANTS.STRINGS_SPACECODE_SAS = 'Spacecode Healthcare S.A. ';
CONSTANTS.STRINGS_ALLRIGHTSRESERVED = 'All rights reserved. ';
CONSTANTS.STRINGS_FORGOTYOURPASS = 'Forgot Your Password?';
CONSTANTS.STRINGS_OTPLOGIN = 'Login via OTP';
CONSTANTS.STRINGS_OTPLOGIN_SUBHEADING = 'Please enter the OTP, which you have received in your registered email.';
CONSTANTS.STRINGS_FORGOTPASS_SUBHEADING =
    "Don't worry. Recovering the password is easy. Just tell us the email address you have registered with BloodSpace.";

CONSTANTS.NEXT = 'Next';
CONSTANTS.NEXT_RECORD = 'Next Record';
CONSTANTS.COMPLETE = 'Complete';
CONSTANTS.EDIT = 'Edit';
CONSTANTS.LABELS_DEVICE_TYPE = 'Device Type';
CONSTANTS.LABELS_CLIENT = 'Branch';

CONSTANTS.ADD_HOSPITAL = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_HOSPITAL;
CONSTANTS.ADD_USER = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_USER;
CONSTANTS.ADD_DEVICE = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_DEVICE;
CONSTANTS.ADD_MOVEMENT = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_MANAGE_MOVEMENT;
CONSTANTS.ADD_RULE = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_MANAGE_RULE;
CONSTANTS.ADD_ALERTS_WARNINGS = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_ALERTS_WARNINGS;
CONSTANTS.ADD_LOCATION = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_LOCATION;
CONSTANTS.ADD_PRODUCT = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_PRODUCT;
CONSTANTS.ADD_STOCK = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_STOCK;
CONSTANTS.ADD_HEALTH_AUTHORITY = CONSTANTS.NAME_ADD + ' ' + CONSTANTS.NAME_HEALTH_AUTHORITY;
CONSTANTS.IS_INVALID = 'is invalid.';
CONSTANTS.IS_INVALID_PHONE = CONSTANTS.IS_INVALID + ' ' + '(only numbers are allowed. For ex. (020) 1234 5678)';
CONSTANTS.ERROR_PASSWORD =
    'Minimum eight characters, at least one uppercase ,lowercase letter , a number and a special character';

//Validation_errors
CONSTANTS.ERROR_USERNAMES = 'You must provide a username.';
CONSTANTS.ERROR_PASSWORD = 'You must provide a password.';

//add_hospital_labels
CONSTANTS.LABEL_HOSPITAL_NAME = 'Branch Name';
CONSTANTS.LABEL_HOSPITAL_CODE = 'Branch Code';
CONSTANTS.LABEL_ADDRESS = 'Address';
CONSTANTS.LABEL_ADDRESS1 = 'Address Line 1';
CONSTANTS.LABEL_ADDRESS2 = 'Address Line 2';
CONSTANTS.LABEL_CITY = 'City';
CONSTANTS.LABEL_STATE = 'State';
CONSTANTS.LABEL_ZIPCODE = 'Zip Code';
CONSTANTS.LABEL_PHONE = 'Phone';
CONSTANTS.LABEL_UPLOAD_IMAGE = 'Upload Image';
//confirmationDialog
CONSTANTS.LABEL_ARE_YOU_SURE = 'Are you sure you want to deactivate this record?';
CONSTANTS.LABEL_SUSPEND_USER = 'Suspend User Period';
CONSTANTS.LABEL_COMMENT = 'Comments';
CONSTANTS.LABEL_ARE_YOU_SURE_DELETE = 'Are you sure you want to delete selected record?';
CONSTANTS.LABEL_ARE_YOU_SURE_DEACTIVATE = 'Are you sure you want to deactivate selected record?';

//add_location_labels
CONSTANTS.LABEL_LOCATION_NAME = CONSTANTS.NAME_LOCATION + ' ' + CONSTANTS.NAME;
CONSTANTS.LABEL_LOCATION_CODE = CONSTANTS.NAME_LOCATION + ' ' + CONSTANTS.CODE;
CONSTANTS.LABEL_LOCATION_TYPE = CONSTANTS.NAME_LOCATION + ' ' + CONSTANTS.NAME_TYPE;

//add_device_labels
CONSTANTS.LABEL_LOCATION = 'Location';
CONSTANTS.LABEL_CLIENT = 'Branch';
CONSTANTS.LABEL_SERIAL_NUMBER = 'Serial Number';
(CONSTANTS.LABEL_IP_ADDRESS = 'IP Address'), (CONSTANTS.LABEL_DEVICE_TYPE = 'Device Type');

//add_user_labels
CONSTANTS.LABEL_FIRST_NAME = 'First Name';
CONSTANTS.LABEL_LAST_NAME = 'Last Name';
CONSTANTS.LABEL_BADGE_ID = 'Badge ID';
CONSTANTS.LABEL_USER_GROUP = 'User Group';
CONSTANTS.LABEL_DESIGNATION = 'Designation';
//add_stock_labels
CONSTANTS.LABEL_HEADER_1 = 'Header 1';
CONSTANTS.LABEL_HEADER_2 = 'Header 2';
CONSTANTS.LABEL_HEADER_3 = 'Header 3';
CONSTANTS.LABEL_HEADER_4 = 'Header 4';
CONSTANTS.LABEL_HEADER_5 = 'Header 5';
CONSTANTS.LABEL_HEADER_6 = 'Header 6';
CONSTANTS.LABEL_STATUS = 'Status';
CONSTANTS.LABEL_SAMPLE_DATA = 'Import Data Excel:';
CONSTANTS.IS_EMPTY = "Access Denied";
CONSTANTS.IS_EMPTY_SUBHEADING = "you don't have a permission to access this page.";

//Validation_errors
CONSTANTS.IS_REQUIRED = ' is required';
CONSTANTS.ERROR_USERNAMES = CONSTANTS.USERNAME + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_PASSWORD = CONSTANTS.PASSWORD + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_HOSPITAL_NAME = CONSTANTS.LABEL_HOSPITAL_NAME + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_HOSPITAL_CODE = CONSTANTS.LABEL_HOSPITAL_CODE + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_ADDRESS = CONSTANTS.LABEL_ADDRESS + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_ADDRESS_1 = CONSTANTS.LABEL_ADDRESS1 + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_ADDRESS_2 = CONSTANTS.LABEL_ADDRESS2 + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_CITY = CONSTANTS.LABEL_CITY + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_STATE = CONSTANTS.LABEL_STATE + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_ZIPCODE = CONSTANTS.LABEL_ZIPCODE + ' ' + CONSTANTS.IS_INVALID;
CONSTANTS.ERROR_PHONE = CONSTANTS.LABEL_PHONE + ' ' + CONSTANTS.IS_INVALID_PHONE;
CONSTANTS.ERROR_LOCATION_ID = CONSTANTS.LABEL_LOCATION_ID + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_CLIENT_ID = CONSTANTS.LABEL_CLIENT_ID + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_SERIAL_NUMBER = CONSTANTS.LABEL_SERIAL_NUMBER + CONSTANTS.IS_REQUIRED;

CONSTANTS.ERROR_DEVICE_TYPE_ID = CONSTANTS.LABEL_DEVICE_TYPE_ID + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_FIRST_NAME = CONSTANTS.LABEL_FIRST_NAME + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_LAST_NAME = CONSTANTS.LABEL_LAST_NAME + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_BADGE_ID = CONSTANTS.LABEL_BADGE_ID + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_DESIGNATION = CONSTANTS.LABEL_DESIGNATION + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_ROLE_ID = CONSTANTS.LABEL_ROLE_ID + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_USER_GROUP_ID = CONSTANTS.LABEL_USER_GROUP_ID + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_LOCATION_NAME = CONSTANTS.LABEL_LOCATION_NAME + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_LOCATION_CODE = CONSTANTS.LABEL_LOCATION_CODE + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_DESCRIPTION = CONSTANTS.NAME_DESCRIPTION + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_LOCATION_TYPE = CONSTANTS.LABEL_LOCATION_TYPE + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_ADDED_BY = CONSTANTS.LABEL_ADDED_BY + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_HEADER_1 = CONSTANTS.LABEL_HEADER_1 + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_HEADER_2 = CONSTANTS.LABEL_HEADER_2 + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_HEADER_3 = CONSTANTS.LABEL_HEADER_3 + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_HEADER_4 = CONSTANTS.LABEL_HEADER_4 + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_HEADER_5 = CONSTANTS.LABEL_HEADER_5 + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_HEADER_6 = CONSTANTS.LABEL_HEADER_6 + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_STATUS = CONSTANTS.LABEL_STATUS + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_HOSPITALS = CONSTANTS.NAME_HOSPITALS + CONSTANTS.IS_REQUIRED;
CONSTANTS.ERROR_UNIT_ID = 'Invalid Unit ID, Length should be minimum 13 charecters';
CONSTANTS.ERROR_UNIT_ID_MATCH = 'Enter a valid Unit ID';
CONSTANTS.ERROR_IP_ADDRESS = 'IP address is invalid';
CONSTANTS.ERROR_SUSPEND_USER = 'Invalid! , Only numbers are allowed';

//buttons
CONSTANTS.LOGIN_BUTTON = 'Login';
CONSTANTS.LOGIN_OTPBUTTON = 'Login via OTP';
CONSTANTS.SEND_BUTTON = 'Send';
CONSTANTS.EXPORT = 'Export';
CONSTANTS.SAVE_OR_ADD = 'Save/Add';
CONSTANTS.ADD = 'Add';
CONSTANTS.CANCEL = 'Cancel';
CONSTANTS.CONTINUE = 'Continue';
CONSTANTS.SAVE = 'Save';
CONSTANTS.ADD_NEW_RECIPIENT = 'Add New Recipient';

CONSTANTS.UNDERSTOOD = 'Understood';
CONSTANTS.BACK = 'Back';
CONSTANTS.ASSIGN = 'Assign';
CONSTANTS.UNASSIGN = 'Unassign';
(CONSTANTS.NEXT = 'Next'), (CONSTANTS.COMPLETE = 'Complete');

CONSTANTS.FIRST_NAME = 'First Name';
CONSTANTS.LAST_NAME = 'Last Name';
CONSTANTS.BLOOD_GROUP = 'Blood group';
CONSTANTS.DOB = 'Date of birth';
CONSTANTS.RECIPIENT_ID = 'Recipient ID';
CONSTANTS.MALE = 'Male';
CONSTANTS.FEMALE = 'Female';
CONSTANTS.GENDER = 'Gender';
CONSTANTS.PLAC_HOLDER_ASSIGN_UI = 'Recipient Name / MRN / Unit ID';
CONSTANTS.SHOWING = 'Showing';
CONSTANTS.RESULTS = 'results';
CONSTANTS.ADD_NEW_RECIPIENT = 'Add New Recipient';
CONSTANTS.EMERGENCY_STOCK_ASSIGN = 'Emergency Stock Assign';
CONSTANTS.NOT_FOUND = 'Not Found';
CONSTANTS.COMMENTS = 'Comments';
CONSTANTS.COMMENTS_PLACE_HOLDER = 'Comments will be here';
CONSTANTS.UNITS = 'Units';
CONSTANTS.ARE_YOU_SURE_WANT_TO_ASSIGN = 'Are you sure you want to assign';
CONSTANTS.ARE_YOU_SURE_WANT_TO_UNASSIGN = 'Are you sure you want to unassign';
CONSTANTS.EMERGENCY_ASSIGN = ' The units are getting assigned for emergency';
CONSTANTS.UNIT_NOT_AVAILABLE = 'Unit not available';
CONSTANTS.UNIT = 'UNIT';
CONSTANTS.HAS_ALREADY_ASSIGNED = 'has already been assigned to another patient';
CONSTANTS.SAME_DERESERVATION_DATE = 'Same dereservation date and time for all.';
CONSTANTS.SAME_DEVICE = 'Same device for all.';
CONSTANTS.DERESERVATION_DATE = 'Dereservation Date';
CONSTANTS.PRODUCT_CODE = 'Product code';
CONSTANTS.UNIT_ID_NUMBER = 'Unit ID';
CONSTANTS.PLEASE_SCAN_BAR_CODE_OR_ENTER_MANUALLY = 'Please scan Unit ID barcode(s) or enter data manually';
CONSTANTS.INVALID = 'Invalid';
CONSTANTS.INVALID_RECIPIENT_ID = 'Invalid recipient ID';
CONSTANTS.INVALID_FIRST_NAME = 'Invalid first name';
CONSTANTS.INVALID_LAST_NAME = 'Invalid last name';
CONSTANTS.SELECT_BLOOD_GROUP = 'Please select blood group';
CONSTANTS.UNIT_ID = 'Unit ID *';
CONSTANTS.UNIT_ID_FILTER = 'Unit ID';

CONSTANTS.ACTIONS = 'Actions';
CONSTANTS.ROWS_PER_PAGE = 'Rows per page';
CONSTANTS.RESULT = 'Result';
CONSTANTS.INCORRECT_ENTRY = 'Incorrect entry.';
CONSTANTS.DIALOG_UNIT_TEXT = 'Unit';

//Settings Page Constants
CONSTANTS.STATIC = 'Static';
CONSTANTS.LIVE = 'Live';
CONSTANTS.LABEL_APPLICATION_BEHAVIOR = 'Application Behavior :';
CONSTANTS.LABEL_DATE_FORMATS = 'Date Formats';
CONSTANTS.LABEL_DATE_FORMAT_ONE = 'MMM-DD-YYYY HH:mm a';
CONSTANTS.LABEL_DATE_FORMAT_TWO = 'MM-DD-YYYY HH:mm a';
CONSTANTS.LABEL_DATE_FORMAT_THREE = 'DD-MMM-YYYY HH:mm a';
CONSTANTS.LABEL_DATE_FORMAT_FOUR = 'DD-MM-YYYY HH:mm a';
CONSTANTS.LABLE_DOWNLOAD_EXCEL = 'Download Excel File :';
CONSTANTS.SET_ASSOCIATE_UNIT_VALIDATION = 'SET_ASSOCIATE_UNIT_VALIDATION';
CONSTANTS.ASSOCIATE_UNIT_VALIDATION_LABEL = 'Associate Unit Validation';
CONSTANTS.DEVICE_WRTIE_TAG = 'Device Write Tag';
CONSTANTS.DEVICE_RULE_BREAK = 'Device Rule Break';
CONSTANTS.USER_RULE_BREAK = 'User Rule Break';
CONSTANTS.PRODUCT_RULE_BREAK = 'Product Rule Break';
CONSTANTS.NOTIFICATION_SETTINGS_LOADING = 'NOTIFICATION_SETTINGS_LOADING';
CONSTANTS.NOTIFICATION_SETTINGS_SUCCESS = 'NOTIFICATION_SETTINGS_SUCCESS';
CONSTANTS.NOTIFICATION_SETTINGS_ERROR = 'NOTIFICATION_SETTINGS_ERROR';

CONSTANTS.SWAPOUTEMAIL_REQUEST = 'NOTIFICATION_SWAPOUT_REQUEST';
CONSTANTS.SWAPOUTEMAIL_SUCCESS = 'NOTIFICATION_SWAPOUTEMAIL_SUCCESS';
CONSTANTS.SWAPOUTEMAIL_ERROR = 'NOTIFICATION_SWAPOUTEMAIL_ERROR';

CONSTANTS.CLEAR_NOTIFICATION_SETTINGS_RESPONSE = 'CLEAR_NOTIFICATION_SETTINGS_RESPONSE';

//add_hospital_labels
CONSTANTS.LABEL_NAME = 'Branch Name';
CONSTANTS.LABEL_CODE = 'Branch Code';
CONSTANTS.LABEL_ADDRESS1 = 'Address Line 1';
CONSTANTS.LABEL_ADDRESS2 = 'Address Line 2';
CONSTANTS.LABEL_CITY = 'City';
CONSTANTS.LABEL_STATE = 'State';
CONSTANTS.LABEL_ZIPCODE = 'Zip Code';
CONSTANTS.LABEL_UPLOAD_IMAGE = 'Upload Image';

// pre-endcoded form
CONSTANTS.LABEL_PRODUCTCODE = 'Product Code *';
CONSTANTS.LABEL_DONATIONID = 'Unit ID *';
CONSTANTS.LABEL_BLOODGROUP = 'Blood Group(ABO/RH) *';
CONSTANTS.LABEL_TAGID = 'Tag ID*';
CONSTANTS.LABEL_DIMENSION = 'Dimensions';
CONSTANTS.LABEL_EXPIRYDATE = 'Expiry Date and Time *';
CONSTANTS.LABEL_COLLECTIONDATE = 'Collection Date and Time';
CONSTANTS.LABEL_SPECIALTESTING = 'Special Testing ';
CONSTANTS.LABEL_CHECK = 'Check';
CONSTANTS.LABEL_SEARCHHOSPITAL = 'Select Branch';
CONSTANTS.LABEL_LOCATION = 'Location';
CONSTANTS.LABEL_STORAGE = 'Storage';
CONSTANTS.LABEL_SINGLEVIEW = 'Single View';
CONSTANTS.LABEL_LISTVIEW = 'List View';
CONSTANTS.LABEL_BULKVIEW = 'Bulk View';
CONSTANTS.LABEL_UNITON = ' Units on Device';
CONSTANTS.LABEL_OF = 'of ';
CONSTANTS.LABEL_OR = 'Or ';
CONSTANTS.LABEL_ASSOCIATEUNITS = 'Associate unit';
CONSTANTS.LABEL_TIPS = 'TIPS';
CONSTANTS.LABEL_SINGLEVIEWTEXT =
    ' You can view unit one by one and are able to save individually as you navigate the through arrows';
CONSTANTS.LABEL_LISTVIEWTEXT = ' You can select one or multiple units from the list and save them to the database';
(CONSTANTS.LABEL_REQUIREDFIELD = ' * indicates a required field'), (CONSTANTS.LABEL_PLEASE = 'Please ');
CONSTANTS.LABEL_SCAN = 'scan ';
CONSTANTS.LABEL_THE = 'the ';
CONSTANTS.LABEL_BARCODE = 'barcode ';
CONSTANTS.LABEL_AUTOMATICALLY = ' that the fields are completed automatically.';
CONSTANTS.LABEL_YOUCANALSO = ' You can also use';
CONSTANTS.LABEL_INPUT = 'input ';
CONSTANTS.LABEL_THEM = 'them ';
CONSTANTS.LABEL_MANUALLY = 'manually ';
CONSTANTS.LABEl_FIELDS = 'on the corresponding fields.';
CONSTANTS.LABEL_UNIT_ON_DEVICE = 'Unit on Device';

//rfid reader

CONSTANTS.NAME_RFID_READER = 'Connected to RFID reader ';
CONSTANTS.NAME_SPACECODE_NUMBER = ' SPACECODE_DEVICE001';
CONSTANTS.LABEL_UNDER_CONSTRUCTION = 'This page is under development';
CONSTANTS.LABEL_UNDER_LAST_UPDATE = 'Updated @ ';

//filtersLables
CONSTANTS.LABELS_USER_NAME = 'User Name';
CONSTANTS.PLACE_HOLDER_SEARCH = 'Search';
CONSTANTS.SPECIAL_TESTING = 'Special Testing';
CONSTANTS.LABELS_ACTIVE = 'Active';
CONSTANTS.LABELS_HOSPITAL_NUMBER = 'Branch Number';
CONSTANTS.LABELS_CARRIER_TYPE = 'Carrier Type';
CONSTANTS.END_POINT = 'End Point';
CONSTANTS.START_POINT = 'Start Point';
CONSTANTS.CREATION_DATE = 'Creation Date';
CONSTANTS.PRODUCTION_DATE = 'Production Date';
CONSTANTS.EXPIRATION_DATE = 'Expiration Date';
CONSTANTS.LABELS_WEIGHT = 'Weight';
CONSTANTS.LABELS_COLLECTION_DATE = 'Collection Date';
CONSTANTS.LABELS_INTERNAL_CODE = 'Internal Code';
CONSTANTS.LABELS_RFID = 'RFID';
CONSTANTS.DEVICE_SERIAL_NUMBER = 'Device Serial Number';
CONSTANTS.DEVICE_LOCATION = 'Device Location';
CONSTANTS.LOCATION_TYPE = 'Location Type';
CONSTANTS.EXPIRY_DATE = 'Expiry Date';
CONSTANTS.LABELS_PRODUCT_CODE = 'Product Code';
CONSTANTS.LABEL_CITY = 'City';
CONSTANTS.USER_ROLE = 'User Role';
CONSTANTS.USER_GROUP = 'User Group';

//colorConstants
CONSTANTS.COLOR_PRIMAY_MAIN = '#004372';
CONSTANTS.COLOR_PRIMAY_ICON = '#777777';
CONSTANTS.COLOR_SECONDARY_MAIN = '#ffffff';
CONSTANTS.COLOR_BACKGROUND_DEFAULT = '#f2f7fb';
CONSTANTS.COLOR_LIST_MAIN = '#D9E3EB';
CONSTANTS.COLOR_LABEL_MAIN = '#777777';

CONSTANTS.COLOR_DARK_GRAY = '#ACACAC';
CONSTANTS.COLOR_GRAY = '#777777';
CONSTANTS.COLOR_WHITE = '#ffffff';
CONSTANTS.COLOR_GREEN = '#31902D';
CONSTANTS.COLOR_GAMBOGE = '#da9100';
CONSTANTS.COLOR_RED = '#F50000';
CONSTANTS.COLOR_PERSIAN_BLUE = '#0B3FE4';
CONSTANTS.COLOR_HONEY_DEW = '#ECFAEC';
CONSTANTS.COLOR_SUNDOWN = '#FAA2A2';
CONSTANTS.COLOR_KHAKI = '#f7e98e';
CONSTANTS.COLOR_PERIWINKLE = '#B9C9FB';
CONSTANTS.COLOR_ALUMINIUM = '#8a8c8d';
CONSTANTS.COLOR_BLACK = '#000000';
CONSTANTS.COLOR_DIM_GRAY = '#676767';
CONSTANTS.COLOR_QUARTZ = '#DFE0EB';
CONSTANTS.COLOR_FREE_SPEECH_RED = '#BA1700';

CONSTANTS.BASEURL = '/api/v1/';

CONSTANTS.TITLE_PRIMARY = 'Primary';
CONSTANTS.TITLE_MAGENTA = 'Magenta';
CONSTANTS.TITLE_RED = 'Red';
CONSTANTS.TITLE_GREEN = 'Green';
CONSTANTS.TITLE_PURPLE = 'Purple';
CONSTANTS.TITLE_DARK = 'Dark';

//DataBase Table Constants
CONSTANTS.DEFAULT_VALUE = 'Default Value';
CONSTANTS.VALIDATION_TYPE = 'Validation Type';
CONSTANTS.REFERENCE = 'Reference';
CONSTANTS.ALLOW_NULL = 'Allow Null';
CONSTANTS.UNIQUE = 'Unique';
CONSTANTS.REQUIRED = 'Required';
CONSTANTS.INDEXED = 'Indexed';
CONSTANTS.ACTION = 'Action';
CONSTANTS.ADD_COLLECTIONS = 'Add Collection';
CONSTANTS.SELECT_COLLECTIONS = 'Select Collections';
CONSTANTS.SELECT_TYPE = 'Select type';
CONSTANTS.SELECT_DATA_TYPE = 'Select Data type';
CONSTANTS.TRUE = 'True';
CONSTANTS.FALSE = 'False';

//VOUCHER CONSTANTS

CONSTANTS.VOUCHER_DATA_REQUEST = 'VOUCHER_DATA_REQUEST';
CONSTANTS.VOUCHER_DATA_SUCCESS = 'VOUCHER_DATA_SUCCESS';
CONSTANTS.VOUCHER_DATA_ERROR = 'VOUCHER_DATA_ERROR';
CONSTANTS.VOUCHER_ROW_REQUEST = 'VOUCHER_ROW_REQUEST';
CONSTANTS.VOUCHER_ROW_SUCCESS = 'VOUCHER_ROW_SUCCESS';
CONSTANTS.VOUCHER_ROW_ERROR = 'VOUCHER_ROW_ERROR';
CONSTANTS.CLEAR_VOUCHER = 'CLEAR_VOUCHER';

//dataBase Constants
CONSTANTS.LABEL = 'Label';

//Fields constants
CONSTANTS.GET_FIELDS_REQUEST = 'GET_FIELDS_REQUEST';
CONSTANTS.GET_FIELDS_SUCCESS = 'GET_FIELDS_SUCCESS ';
CONSTANTS.GET_FIELDS_ERROR = 'GET_FIELDS_ERROR';

CONSTANTS.POST_FIELDS_REQUEST = 'POST_FIELDS_REQUEST';
CONSTANTS.POST_FIELDS_SUCCESS = 'POST_FIELDS_SUCCESS ';
CONSTANTS.POST_FIELDS_ERROR = 'POST_FIELDS_ERROR';
CONSTANTS.CLEAR_POST_FIELDS = 'CLEAR_POST_FIELDS';
CONSTANTS.DEV_URL = packageJson.proxy;

CONSTANTS.POST_SWAPOUT_REQUEST = 'POST_SWAPOUT_REQUEST';
CONSTANTS.POST_SWAPOUT_SUCCESS = 'POST_SWAPOUT_SUCCESS';
CONSTANTS.POST_SWAPOUT_ERROR = 'POST_SWAPOUT_ERROR';

CONSTANTS.PUT_SWAPOUTCANCEL_REQUEST = 'PUT_SWAPOUTCANCEL_REQUEST';
CONSTANTS.PUT_SWAPOUTCANCEL_SUCCESS = 'PUT_SWAPOUTCANCEL_SUCCESS';
CONSTANTS.PUT_SWAPOUTCANCEL_ERROR = 'PUT_SWAPOUTCANCEL_ERROR';

CONSTANTS.PUT_FIELDS_REQUEST = 'PUT_FIELDS_REQUEST';
CONSTANTS.PUT_FIELDS_SUCCESS = 'PUT_FIELDS_SUCCESS ';
CONSTANTS.PUT_FIELDS_ERROR = 'PUT_FIELDS_ERROR';
CONSTANTS.CLEAR_PUT_FIELDS = 'CLEAR_PUT_FIELDS';

CONSTANTS.PUT_SWAPOUT_REQUEST = 'PUT_SWAPOUT_REQUEST';
CONSTANTS.PUT_SWAPOUT_SUCCESS = 'PUT_SWAPOUT_SUCCESS';
CONSTANTS.PUT_SWAPOUT_ERROR = 'PUT_SWAPOUT_ERROR'


CONSTANTS.PUT_DISPLAY_CONFIG_REQUEST = 'PUT_DISPLAY_CONFIG_REQUEST';
CONSTANTS.PUT_DISPLAY_CONFIG_SUCCESS = 'PUT_DISPLAY_CONFIG_SUCCESS ';
CONSTANTS.PUT_DISPLAY_CONFIG_ERROR = 'PUT_DISPLAY_CONFIG_ERROR';
CONSTANTS.CLEAR_DISPLAY_CONFIG_PUT = 'CLEAR_DISPLAY_CONFIG_PUT';

CONSTANTS.DELETE_ACTIVEDEACTIVE_REQUEST = 'DELETE_ACTIVEDEACTIVE_REQUEST';
CONSTANTS.DELETE_ACTIVEDEACTIVE_SUCCESS = 'DELETE_ACTIVEDEACTIVE_SUCCESS ';
CONSTANTS.DELETE_ACTIVEDEACTIVE_ERROR = 'DELETE_ACTIVEDEACTIVE_ERROR';

CONSTANTS.GET_STOCKS_DATA_REQUEST = 'GET_STOCKS_DATA_REQUEST';
CONSTANTS.GET_STOCKS_DATA_SUCCESS = 'GET_STOCKS_DATA_SUCCESS';
CONSTANTS.GET_STOCKS_DATA_FAIL = 'GET_STOCKS_DATA_FAIL';

//AssignPage and Recipient page
CONSTANTS.GET_RECIPIENTS_REQUEST = 'GET_RECIPIENTS_REQUEST';
CONSTANTS.GET_RECIPIENTS_SUCCESS = 'GET_RECIPIENTS_SUCCESS';
CONSTANTS.GET_RECIPIENTS_ERROR = 'GET_RECIPIENTS_ERROR';
CONSTANTS.CLEAR_RECIPIENT_DATA = 'CLEAR_RECIPIENT_DATA';

CONSTANTS.CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';

CONSTANTS.GET_PRODUCT_CODE_REQUEST = 'GET_PRODUCT_CODE_REQUEST';
CONSTANTS.GET_PRODUCT_CODE_SUCCESS = 'GET_PRODUCT_CODE_SUCCESS';
CONSTANTS.GET_PRODUCT_CODE_ERROR = 'GET_PRODUCT_CODE_ERROR';

CONSTANTS.POST_ADD_UNIT_REQUEST = 'POST_ADD_UNIT_REQUEST';
CONSTANTS.POST_ADD_UNIT_SUCCESS = 'POST_ADD_UNIT_SUCCESS';
CONSTANTS.POST_ADD_UNIT_ERROR = 'POST_ADD_UNIT_ERROR';

CONSTANTS.GET_UNIT_SEARCH_REQUEST = 'GET_UNIT_SEARCH_REQUEST';
CONSTANTS.GET_UNIT_SEARCH_SUCCESS = 'GET_UNIT_SEARCH_SUCCESS';
CONSTANTS.GET_UNIT_SEARCH_ERROR = 'GET_UNIT_SEARCH_ERROR';

CONSTANTS.GET_BATCH_SEARCH_REQUEST = 'GET_BATCH_SEARCH_REQUEST';
CONSTANTS.GET_BATCH_SEARCH_SUCCESS = 'GET_BATCH_SEARCH_SUCCESS';
CONSTANTS.GET_BATCH_SEARCH_ERROR = 'GET_BATCH_SEARCH_ERROR';

CONSTANTS.CLEAR_UNIT_ID_SEARCH = 'CLEAR_UNIT_ID_SEARCH';
CONSTANTS.CLEAR_POST_UNIT_RESPONSE = 'CLEAR_POST_UNIT_RESPONSE';

CONSTANTS.GET_BLOOD_GROUPS_REQUEST = 'GET_BLOOD_GROUPS_REQUEST';
CONSTANTS.GET_BLOOD_GROUPS_SUCCESS = 'GET_BLOOD_GROUPS_SUCCESS';
CONSTANTS.GET_BLOOD_GROUPS_ERROR = 'GET_BLOOD_GROUPS_ERROR';

CONSTANTS.ADD_SKU_REQUEST = 'ADD_SKU_REQUEST';
CONSTANTS.ADD_SKU_SUCCESS = 'ADD_SKU_SUCCESS';
CONSTANTS.ADD_SKU_BULK_SUCCESS = 'ADD_SKU_BULK_SUCCESS';
CONSTANTS.ADD_SKU_ERROR = 'ADD_SKU_ERROR';
CONSTANTS.CLEAR_ADD_SKU_DATA = 'CLEAR_ADD_SKU_DATA';

//For Rules Api

CONSTANTS.CONDITION_REQUEST = 'CONDITION_REQUEST';
CONSTANTS.CONDITION_SUCCESS = 'CONDITION_SUCCESS';
CONSTANTS.CONDITION_ERROR = 'CONDITION_ERROR';

CONSTANTS.GETALL_REQUEST = 'GETALL_REQUEST';
CONSTANTS.GETALL_SUCCESS = 'GETALL_SUCCESS';
CONSTANTS.GETALL_ERROR = 'GETALL_REQUEST';

CONSTANTS.GET_FACT_TYPE_SUCCESS = 'GET_FACT_TYPE_SUCCESS';
CONSTANTS.GET_FACT_TYPE_ERROR = 'GET_FACT_TYPE_ERROR';
CONSTANTS.GET_FACT_TYPE_REQUEST = 'GET_FACT_TYPE_REQUEST';

CONSTANTS.GET_FACT_CONDITION_SUCCESS = 'GET_FACT_CONDITION_SUCCESS';
CONSTANTS.GET_FACT_CONDITION_ERROR = 'GET_FACT_CONDITION_ERROR';
CONSTANTS.GET_FACT_CONDITION_REQUEST = 'GET_FACT_CONDITION_REQUEST';

CONSTANTS.GET_FACT_DYNAMIC_TYPE_SUCCESS = 'GET_FACT_DYNAMIC_TYPE_SUCCESS';
CONSTANTS.GET_FACT_DYNAMIC_TYPE_ERROR = 'GET_FACT_DYNAMIC_TYPE_ERROR';
CONSTANTS.GET_FACT_DYNAMIC_TYPE_REQUEST = 'GET_FACT_DYNAMIC_TYPE_REQUEST';

CONSTANTS.GET_NOTIFICATION_TYPE_SUCCESS = 'GET_NOTIFICATION_TYPE_SUCCESS';
CONSTANTS.GET_NOTIFICATION_TYPE_ERROR = 'GET_NOTIFICATION_TYPE_ERROR';
CONSTANTS.GET_NOTIFICATION_TYPE_REQUEST = 'GET_NOTIFICATION_TYPE_REQUEST';

CONSTANTS.GET_NOTIFY_BY_TYPE_SUCCESS = 'GET_NOTIFY_BY_TYPE_SUCCESS';
CONSTANTS.GET_NOTIFY_BY_TYPE_ERROR = 'GET_NOTIFY_BY_TYPE_ERROR';
CONSTANTS.GET_NOTIFY_BY_TYPE_REQUEST = 'GET_NOTIFY_BY_TYPE_REQUEST';

CONSTANTS.GET_NOTIFY_DYNAMIC_TYPE_SUCCESS = 'GET_NOTIFY_DYNAMIC_TYPE_SUCCESS';
CONSTANTS.GET_NOTIFY_DYNAMIC_TYPE_ERROR = 'GET_NOTIFY_DYNAMIC_TYPE_ERROR';
CONSTANTS.GET_NOTIFY_DYNAMIC_TYPE_REQUEST = 'GET_NOTIFY_DYNAMIC_TYPE_REQUEST';

CONSTANTS.GET_USERS_IN_RULES_SUCCESS = 'GET_USERS_IN_RULES_SUCCESS';
CONSTANTS.GET_USERS_IN_RULES_ERROR = 'GET_USERS_IN_RULES_ERROR';
CONSTANTS.GET_USERS_IN_RULES_REQUEST = 'GET_USERS_IN_RULES_REQUEST';

CONSTANTS.GET_PRODUCTS_IN_RULES_SUCCESS = 'GET_PRODUCTS_IN_RULES_SUCCESS';
CONSTANTS.GET_PRODUCTS_IN_RULES_ERROR = 'GET_PRODUCTS_IN_RULES_ERROR';
CONSTANTS.GET_PRODUCTS_IN_RULES_REQUEST = 'GET_PRODUCTS_IN_RULES_REQUEST';

CONSTANTS.GET_RESOLUTION_TYPE_SUCCESS = 'GET_RESOLUTION_TYPE_SUCCESS';
CONSTANTS.GET_RESOLUTION_TYPE_ERROR = 'GET_RESOLUTION_TYPE_ERROR';
CONSTANTS.GET_RESOLUTION_TYPE_REQUEST = 'GET_RESOLUTION_TYPE_REQUEST';

CONSTANTS.GET_SELECTED_FACT_SUCCESS = 'GET_SELECTED_FACT_SUCCESS';
CONSTANTS.GET_SELECTED_FACT_ERROR = 'GET_SELECTED_FACT_ERROR';
CONSTANTS.GET_SELECTED_FACT_REQUEST = 'GET_SELECTED_FACT_REQUEST';

CONSTANTS.GET_SELECTED_NOTIFICATION_SUCCESS = 'GET_SELECTED_NOTIFICATION_SUCCESS';
CONSTANTS.GET_SELECTED_NOTIFICATION_ERROR = 'GET_SELECTED_NOTIFICATION_ERROR';
CONSTANTS.GET_SELECTED_NOTIFICATION_REQUEST = 'GET_SELECTED_NOTIFICATION_REQUEST';

CONSTANTS.GET_SELECTED_RESOLUTION_SUCCESS = 'GET_SELECTED_RESOLUTION_SUCCESS';
CONSTANTS.GET_SELECTED_RESOLUTION_ERROR = 'GET_SELECTED_RESOLUTION_ERROR';
CONSTANTS.GET_SELECTED_RESOLUTION_REQUEST = 'GET_SELECTED_RESOLUTION_REQUEST';

CONSTANTS.GET_EMAIL_TEMPLATE_SUCCESS = 'GET_EMAIL_TEMPLATE_SUCCESS';
CONSTANTS.GET_EMAIL_TEMPLATE_ERROR = 'GET_EMAIL_TEMPLATE_ERROR';
CONSTANTS.GET_EMAIL_TEMPLATE_REQUEST = 'GET_EMAIL_TEMPLATE_REQUEST';

CONSTANTS.GET_SMS_TEMPLATE_SUCCESS = 'GET_SMS_TEMPLATE_SUCCESS';
CONSTANTS.GET_SMS_TEMPLATE_ERROR = 'GET_SMS_TEMPLATE_ERROR';
CONSTANTS.GET_SMS_TEMPLATE_REQUEST = 'GET_SMS_TEMPLATE_REQUEST';

CONSTANTS.GET_POPUP_TEMPLATE_SUCCESS = 'GET_POPUP_TEMPLATE_SUCCESS';
CONSTANTS.GET_POPUP_TEMPLATE_ERROR = 'GET_POPUP_TEMPLATE_ERROR';
CONSTANTS.GET_POPUP_TEMPLATE_REQUEST = 'GET_POPUP_TEMPLATE_REQUEST';

CONSTANTS.POST_RULE_TYPE_REQUEST = 'POST_RULE_TYPE_REQUEST';
CONSTANTS.POST_RULE_TYPE_SUCCESS = 'POST_RULE_TYPE_SUCCESS';
CONSTANTS.POST_RULE_TYPE_ERROR = 'POST_RULE_TYPE_ERROR';
CONSTANTS.CLEAR_RULE_TYPE_POST = 'CLEAR_RULE_TYPE_POST';

CONSTANTS.CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE';
CONSTANTS.FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
CONSTANTS.FORGOT_PASSWORD_REQUEST_SUCCESS = 'FORGOT_PASSWORD_REQUEST_SUCCESS';
CONSTANTS.FORGOT_PASSWORD_REQUEST_ERROR = 'FORGOT_PASSWORD_REQUEST_ERROR';
CONSTANTS.CLEAR_FORGOT_PASSWORD_RESPONSE = 'CLEAR_FORGOT_PASSWORD_RESPONSE';
CONSTANTS.VERIFY_OTP_PASSWORD_REQUEST = 'VERIFY_OTP_PASSWORD_REQUEST';
CONSTANTS.VERIFY_OTP_PASSWORD_REQUEST_SUCCESS = 'VERIFY_OTP_PASSWORD_REQUEST_SUCCESS';
CONSTANTS.VERIFY_OTP_PASSWORD_REQUEST_ERROR = 'VERIFY_OTP_PASSWORD_REQUEST_ERROR';
CONSTANTS.CLEAR_NEW_PASSWORD_SUCCESS_MESSAGE = 'CLEAR_NEW_PASSWORD_SUCCESS_MESSAGE';
CONSTANTS.CODES_RECEIVED = 'CODES_RECEIVED';
CONSTANTS.SOCKET_CONNECT = 'SOCKET_CONNECT';
CONSTANTS.SOCKET_DIALOG = 'SOCKET_DIALOG';
CONSTANTS.SOCKET_DEVICE_VALUE = 'SOCKET_DEVICE_VALUE';
CONSTANTS.SOCKET_DEVICE = 'SOCKET_DEVICE';
CONSTANTS.SOCKET_DEVICE_SERIALNUMBER = 'SOCKET_DEVICE_SERIALNUMBER';
CONSTANTS.SOCKET_DEVICE_STATUS = 'SOCKET_DEVICE_STATUS';
CONSTANTS.SOCKET_DEVICE_CONNECTIONS = 'SOCKET_USER_CONNECTIONS';
CONSTANTS.DELETE_COLLECTION_REQUEST = 'DELETE_COLLECTION_REQUEST';
CONSTANTS.DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
CONSTANTS.DELETE_COLLECTION_ERROR = 'DELETE_COLLECTION_ERROR';
CONSTANTS.CLEAR_COLLECTION_SELECTOR = 'CLEAR_COLLECTION_SELECTOR';
CONSTANTS.BULK_VIEW_REQUEST = 'BULK_VIEW_REQUEST';
CONSTANTS.BULK_VIEW_SUCCESS = 'BULK_VIEW_SUCCESS';
CONSTANTS.BULK_VIEW_ERROR = 'BULK_VIEW_ERROR';
CONSTANTS.FLUSH_ZEBRA_DATA_REQUEST = 'FLUSH_ZEBRA_DATA_REQUEST';
CONSTANTS.FLUSH_ZEBRA_DATA_SUCCES = 'FLUSH_ZEBRA_DATA_SUCCES';
CONSTANTS.FLUSH_ZEBRA_DATA_ERROR = 'FLUSH_ZEBRA_DATA_ERROR';
CONSTANTS.POST_TRANSFER_DATA_REQUEST = 'POST_TRANSFER_DATA_REQUEST';
CONSTANTS.POST_TRANSFER_DATA_SUCCESS = 'POST_TRANSFER_DATA_SUCCESS';
CONSTANTS.POST_TRANSFER_DATA_ERROR = 'POST_TRANSFER_DATA_ERROR';
CONSTANTS.CLEAR_GET_TRANSFER_DATA = 'CLEAR_GET_TRANSFER_DATA';
CONSTANTS.GET_TRANSACTION_DATA_REQUEST = 'GET_TRANSACTION_DATA_REQUEST';
CONSTANTS.GET_TRANSACTION_DATA_SUCCESS = 'GET_TRANSACTION_DATA_SUCCESS';
CONSTANTS.GET_TRANSACTION_DATA_ERROR = 'GET_TRANSACTION_DATA_ERROR';
CONSTANTS.CLEAR_GET_TRANSFER_DATA = 'CLEAR_GET_TRANSFER_DATA';
CONSTANTS.GET_RECEIVED_UNITS = 'GET_RECEIVED_UNITS';
CONSTANTS.CLEAR_RECEIVED_UNITS = 'CLEAR_RECEIVED_UNITS';
CONSTANTS.POST_CHANGE_LOCATION_REQUEST = 'POST_CHANGE_LOCATION_REQUEST';
CONSTANTS.POST_CHANGE_LOCATION_SUCCESS = 'POST_CHANGE_LOCATION_SUCCESS';
CONSTANTS.POST_CHANGE_LOCATION_ERROR = 'POST_CHANGE_LOCATION_ERROR';
CONSTANTS.CLEAR_POST_CHANGE_LOCATION_DATA = 'CLEAR_POST_CHANGE_LOCATION_DATA';
CONSTANTS.GET_API_DETAILS_FETCH = 'GET_API_DETAILS_FETCH';
CONSTANTS.GET_API_DETAILS_SUCCESS = 'GET_API_DETAILS_SUCCESS';
CONSTANTS.GET_API_DETAILS_FAIL = 'GET_API_DETAILS_FAIL';

//FILTER CONSTANTS
CONSTANTS.CLEAR_FILTER_CHIPS = 'CLEAR_FILTER_CHIPS';
CONSTANTS.ADD_FILTER_CHIPS = 'ADD_FILTER_CHIPS';
CONSTANTS.REMOVE_FILTER_CHIPS = 'REMOVE_FILTER_CHIPS';

//userAccess
CONSTANTS.GET_USER_ACCESS_REQUEST = 'GET_USER_ACCESS_REQUEST';
CONSTANTS.GET_USER_ACCESS_SUCCESS = 'GET_USER_ACCESS_SUCCESS';
CONSTANTS.GET_USER_ACCESS_ERROR = 'GET_USER_ACCESS_ERROR';
CONSTANTS.POST_USER_ROLE_ACCESS_REQUEST = 'POST_USER_ROLE_ACCESS_REQUEST';
CONSTANTS.POST_USER_ROLE_ACCESS_SUCCESS = 'POST_USER_ROLE_ACCESS_SUCCESS';
CONSTANTS.POST_USER_ROLE_ACCESS_ERROR = 'POST_USER_ROLE_ACCESS_ERROR';
CONSTANTS.CLEAR_USER_ROLE_ACCESS_POST = 'CLEAR_USER_ROLE_ACCESS_POST';
CONSTANTS.ADD_USER_ROLE = 'Add User Role';
CONSTANTS.USER_ACCESS_ROLE = 'User Access Role';
CONSTANTS.ADD_NEW_USER_ACCESS_ROLE = ' + Add New User Access Role';

//App Version
CONSTANTS.GET_VERSION_REQUEST = 'GET_VERSION_REQUEST';
CONSTANTS.GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
CONSTANTS.GET_VERSION_ERROR = 'GET_VERSION_ERROR';

//Request unit
CONSTANTS.ASSIGNWARNING = 'Selected record status is assigned. If u want to assign please change status to unassign.';

//Alert Constants
CONSTANTS.CREATE_ALERT = 'CREATE_ALERT';
CONSTANTS.REMOVE_ALERT = 'REMOVE_ALERT';

//DRAWER CONSTANTS

CONSTANTS.SUB_MENU_CODES_RECEIVED = 'SUB_MENU_CODES_RECEIVED';
CONSTANTS.ARRAY_OF_PATHS = 'ARRAY_OF_PATHS';
CONSTANTS.ARRAY_OF_MENU_MODULES = 'ARRAY_OF_MENU_MODULES';
CONSTANTS.DRAWER_RESPONSE_STORE = 'DRAWER_RESPONSE_STORE';

//RECEIVE UNITS
CONSTANTS.GET_RECEIVE_UNITS_REQUEST = 'GET_RECEIVE_UNITS_REQUEST';
CONSTANTS.GET_RECEIVE_UNITS_SUCCESS = 'GET_RECEIVE_UNITS_SUCCESS';
CONSTANTS.GET_RECEIVE_UNITS_ERROR = 'GET_RECEIVE_UNITS_ERROR';
CONSTANTS.CLEAR_RECEIVE_UNITS_DATA = 'CLEAR_RECEIVE_UNITS_DATA';

//Access Badge remote dashboard

CONSTANTS.PULL_OUT_BY_ID_REQUEST = 'PULL_OUT_BY_ID_REQUEST';
CONSTANTS.PULL_OUT_BY_ID_SUCCESS = 'PULL_OUT_BY_ID_SUCCESS';
CONSTANTS.PULL_OUT_BY_ID_ERROR = 'PULL_OUT_BY_ID_ERROR';
CONSTANTS.PULL_OUT_BY_ID_CLEAR = 'PULL_OUT_BY_ID_CLEAR';

CONSTANTS.OK = 'OK';
CONSTANTS.YES = 'YES';
CONSTANTS.NO = 'NO';

export default CONSTANTS;
