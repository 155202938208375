import { Paper } from '@material-ui/core';
import { MovableTab } from 'common';
import React from 'react';
import BulkLoading from '../bulkLoading';
import BulkUnload from '../bulkUnload/bulkUnload';

const BulkLoadAndUnload = ({ accessableCodes, handleDeviceExit }) => {
    const tabList = ['Load', 'Un Load'];
    const tabPanelItem = ['Load', 'UnLoad'];
    const tabPanelItemObj = {
        Load: <BulkLoading accessableCodes={accessableCodes} handleDeviceExit={handleDeviceExit} />,
        UnLoad: <BulkUnload accessableCodes={accessableCodes} handleDeviceExit={handleDeviceExit} />
    };

    return (
        <>
            <MovableTab tabSwitch tabList={tabList} tabPanelItem={tabPanelItem} tabPanelItemObj={tabPanelItemObj} />
        </>
    );
};

export default BulkLoadAndUnload;
