import React from 'react';
import moment from 'moment';
import { Grid, Paper, Typography, Card } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './goodUnitsStyles';

const GoodUnits = ({
    goodUnits,
    redirectCounter,
    goodUnitsHeight,
    grayUnits,
    removeunit,
    goodBatchData,
    selectedBatchProduct,
    stillBatchExist,
    getDate
}) => {
    const classes = useStyles();
    const { dateFormat } = useSelector((state) => state.dateFormat);
    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <Grid item xs={12} style={{ display: 'flex', marginBottom: '2px' }}>
                    <Grid item xs={6}>
                        <Paper elevation={0} style={{ padding: 15, display: 'flex', alignItems: 'center', gap: 8 }}>
                            <CheckCircleOutlineIcon fontSize="small" className={classes.tickIcon} />{' '}
                            {removeunit ? (
                                <Typography variant="body1" className={classes.successText}>
                                    Units to be removed are still in Device.
                                </Typography>
                            ) : stillBatchExist ? (
                                <Typography variant="body1" className={classes.successText}>
                                    Batch Related tags to be removed are still in Device.
                                </Typography>
                            ) : (
                                <Typography variant="body1" className={classes.successText}>
                                    {goodUnits?.length} Units has been removed from the Device.
                                </Typography>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} style={{ padding: 15, display: 'flex', alignItems: 'center', gap: 8 }}>
                            <AccessTimeIcon fontSize="small" className={classes.tickIcon} />{' '}
                            {goodUnitsHeight ? (
                                <Typography variant="body1" className={classes.successText}>
                                    Door Locks in {redirectCounter} seconds.
                                </Typography>
                            ) : (
                                <Typography variant="body1" className={classes.successText}>
                                    Redirecting to Dashboard screen in {redirectCounter} seconds.
                                </Typography>
                            )}
                        </Paper>
                    </Grid>
                </Grid>

                <Paper
                    style={{
                        borderRadius: '10px',
                        padding: 25,
                        height: '71vh',
                        overflow: 'auto',
                        maxHeight: '62vh'
                    }}
                    elevation={0}
                >
                    <Grid container style={{ padding: 3 }} spacing={4}>
                        {goodUnits?.map((unit) => (
                            <Grid key={unit.id} item xs={6}>
                                <Card className={unit.check ? classes.cardRoot : classes.cardRootGray}>
                                    <table>
                                        <tr>
                                            <td className={classes.cardHead}>Unit ID# :</td>
                                            <td className={classes.cardDetail}>
                                                {unit.check ? unit?.refSku?.donationCode : unit?.donationCode}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.cardHead}>Blood Group :</td>
                                            <td className={classes.cardDetail}>
                                                {unit.check
                                                    ? unit?.refSku?.bloodgroup?.name
                                                    : unit.bloodgroupId?.[0]?.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.cardHead}>Product Code :</td>
                                            <td className={classes.cardDetail}>
                                                {unit.check
                                                    ? unit?.refSku?.productcode?.code
                                                    : unit?.productcodeId?.[0]?.code}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.cardHead}>Product Group :</td>
                                            <td className={classes.cardDetail}>
                                                {unit.check
                                                    ? unit?.refSku?.productgroup?.name
                                                    : unit?.productgroupId?.[0]?.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.cardHead}>Expiration Date :</td>
                                            <td className={classes.cardDetail}>
                                                {unit.check
                                                    ? unit?.refSku?.expiryDateAndTime
                                                        ? moment(unit?.refSku?.expiryDateAndTime).format(dateFormat)
                                                        : '-'
                                                    : moment(unit?.expiryDateAndTime).format(dateFormat)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.cardHead}>Dereservation Date :</td>
                                            <td className={classes.cardDetail}>
                                                {unit.check
                                                    ? unit?.refSku?.dereservationDate
                                                        ? moment(unit?.refSku?.dereservationDate).format(dateFormat)
                                                        : '-'
                                                    : moment(unit?.dereservationDate).format(dateFormat)}
                                            </td>
                                        </tr>
                                    </table>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default GoodUnits;
